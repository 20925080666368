import { Layout, Typography } from "antd";
import "rc-footer/assets/index.css";
import { Link } from "react-router-dom";
const { Title } = Typography;

const { Header, Content } = Layout;

const Website = () => {
  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        <div className="flexContainer">
          <div className="logoContainer">
            <a className="logo" href="#">
              subtle<small>beta</small>
            </a>
          </div>
          <div className="content">
            <p>Realtime Subtitles for Streamers</p>
            <br />
            <Link to="/app">
              <button>TRY IT NOW</button>
            </Link>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Website;
