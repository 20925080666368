import {
  AudioFilled,
  PauseCircleFilled,
  SettingOutlined,
} from "@ant-design/icons";
import { Badge, Button, Checkbox, Divider, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import "react-color-palette/lib/css/styles.css";
import { useHistory } from "react-router-dom";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import "./App.less";
import { useLocalStorage } from "./providers/useLocalStorage";

const languages = [
  { code: "af", name: "Afrikaans" },
  { code: "am", name: "Amharic" },
  { code: "ar", name: "Arabic" },
  { code: "auto", name: "Automatic" },
  { code: "az", name: "Azerbaijani" },
  { code: "be", name: "Belarusian" },
  { code: "bg", name: "Bulgarian" },
  { code: "bn", name: "Bengali" },
  { code: "bs", name: "Bosnian" },
  { code: "ca", name: "Catalan" },
  { code: "ceb", name: "Cebuano" },
  { code: "zh-CN", name: "Chinese (Simplified)" },
  { code: "zh-TW", name: "Chinese (Traditional)" },
  { code: "co", name: "Corsican" },
  { code: "cs", name: "Czech" },
  { code: "cy", name: "Welsh" },
  { code: "da", name: "Danish" },
  { code: "de", name: "German" },
  { code: "el", name: "Greek" },
  { code: "en", name: "English" },
  { code: "eo", name: "Esperanto" },
  { code: "es", name: "Spanish" },
  { code: "et", name: "Estonian" },
  { code: "eu", name: "Basque" },
  { code: "fa", name: "Persian" },
  { code: "fi", name: "Finnish" },
  { code: "fr", name: "French" },
  { code: "fy", name: "Frisian" },
  { code: "ga", name: "Irish" },
  { code: "gd", name: "Scots Gaelic" },
  { code: "gl", name: "Galician" },
  { code: "gu", name: "Gujarati" },
  { code: "ha", name: "Hausa" },
  { code: "haw", name: "Hawaiian" },
  { code: "he", name: "Hebrew" },
  { code: "hi", name: "Hindi" },
  { code: "hmn", name: "Hmong" },
  { code: "hr", name: "Croatian" },
  { code: "ht", name: "Haitian Creole" },
  { code: "hu", name: "Hungarian" },
  { code: "hy", name: "Armenian" },
  { code: "id", name: "Indonesian" },
  { code: "ig", name: "Igbo" },
  { code: "is", name: "Icelandic" },
  { code: "it", name: "Italian" },
  { code: "iw", name: "Hebrew" },
  { code: "ja", name: "Japanese" },
  { code: "jw", name: "Javanese" },
  { code: "ka", name: "Georgian" },
  { code: "kk", name: "Kazakh" },
  { code: "km", name: "Khmer" },
  { code: "kn", name: "Kannada" },
  { code: "ko", name: "Korean" },
  { code: "ku", name: "Kurdish (Kurmanji)" },
  { code: "ky", name: "Kyrgyz" },
  { code: "la", name: "Latin" },
  { code: "lb", name: "Luxembourgish" },
  { code: "lo", name: "Lao" },
  { code: "lt", name: "Lithuanian" },
  { code: "lv", name: "Latvian" },
  { code: "mg", name: "Malagasy" },
  { code: "mi", name: "Maori" },
  { code: "mk", name: "Macedonian" },
  { code: "ml", name: "Malayalam" },
  { code: "mn", name: "Mongolian" },
  { code: "mr", name: "Marathi" },
  { code: "ms", name: "Malay" },
  { code: "mt", name: "Maltese" },
  { code: "my", name: "Myanmar (Burmese)" },
  { code: "ne", name: "Nepali" },
  { code: "nl", name: "Dutch" },
  { code: "no", name: "Norwegian" },
  { code: "ny", name: "Chichewa" },
  { code: "pa", name: "Punjabi" },
  { code: "pl", name: "Polish" },
  { code: "ps", name: "Pashto" },
  { code: "pt", name: "Portuguese" },
  { code: "ro", name: "Romanian" },
  { code: "ru", name: "Russian" },
  { code: "sd", name: "Sindhi" },
  { code: "si", name: "Sinhala" },
  { code: "sk", name: "Slovak" },
  { code: "sl", name: "Slovenian" },
  { code: "sm", name: "Samoan" },
  { code: "sn", name: "Shona" },
  { code: "so", name: "Somali" },
  { code: "sq", name: "Albanian" },
  { code: "sr", name: "Serbian" },
  { code: "st", name: "Sesotho" },
  { code: "su", name: "Sundanese" },
  { code: "sv", name: "Swedish" },
  { code: "sw", name: "Swahili" },
  { code: "ta", name: "Tamil" },
  { code: "te", name: "Telugu" },
  { code: "tg", name: "Tajik" },
  { code: "th", name: "Thai" },
  { code: "tl", name: "Filipino" },
  { code: "tr", name: "Turkish" },
  { code: "uk", name: "Ukrainian" },
  { code: "ur", name: "Urdu" },
  { code: "uz", name: "Uzbek" },
  { code: "vi", name: "Vietnamese" },
  { code: "xh", name: "Xhosa" },
  { code: "yi", name: "Yiddish" },
  { code: "yo", name: "Yoruba" },
  { code: "zu", name: "Zulu" },
].sort((a: any, b: any) => (a.name > b.name ? 1 : -1));

const { Option } = Select;

var axios = require("axios");
function Translator() {
  const [settings, set_settings] = useLocalStorage("settings", {
    bgcolor: "#111",
    transcriptcolor: "#FFFF00",
    translationcolor: "#FFFFFF",
    fontsize: 16,
    enable_transcriptcopy: true,
    inputlanguage: "en",
    outputlanguage: "zh-CN",
    readback: false,
  });
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [isListening, setIsListening] = useState(false);
  const [original, setOriginal] = useState<any>(false);
  const [translation, setTranslation] = useState<any>(false);
  const handleListing = () => {
    setIsListening(true);
    SpeechRecognition.startListening({
      continuous: true,
    });
  };

  let speech = new SpeechSynthesisUtterance();

  React.useEffect(() => {
    if (speech && settings.outputlanguage) {
      speech.lang = settings.outputlanguage;
    }
  }, [speech, settings]);

  useEffect(() => {
    let listenInterval: any;
    if (isListening) {
      if (transcript.split(" ").length > 20) {
        console.log("consider translating");
      }
      listenInterval = setInterval(() => {
        if (transcript) {
          translate();
        }
      }, 2000);
    }

    return () => {
      clearTimeout(listenInterval);
    };
  }, [isListening, transcript]);

  const translate = async () => {
    setOriginal(transcript);
    var config = {
      method: "get",
      url: `https://5rpxehyd69.execute-api.us-east-1.amazonaws.com/prod/translate?text="${transcript.replace(
        " ",
        "%20"
      )}"&from=${settings.inputlanguage}&to=${settings.outputlanguage}`,
    };
    resetTranscript();

    axios(config)
      .then(function (response: any) {
        console.log(response.data);
        setTranslation(response.data);
        if (settings.readback) {
          speech.text = response.data;
          window.speechSynthesis.speak(speech);
        }
      })
      .catch(function (error: any) {
        console.log(error);
      });
  };
  const stopHandle = () => {
    setIsListening(false);
    SpeechRecognition.stopListening();
  };
  return (
    <div className="App" style={{ backgroundColor: settings.bgcolor }}>
      <header className="App-header" style={{ height: "100vh" }}>
        <Settings
          settings={settings}
          save={async (item: any) => {
            await set_settings(item);
            return;
          }}
          isRecording={isListening}
          toggleRecording={() => (isListening ? stopHandle() : handleListing())}
        />
        {transcript && (
          <div
            style={{
              position: "absolute",
              bottom: 50,
              color: settings.transcriptcolor,
            }}
          >
            <div>{transcript}</div>
          </div>
        )}
        {translation && (
          <>
            <div style={{ color: settings.translationcolor }}>
              {translation}
            </div>
            {settings.enable_transcriptcopy && (
              <div style={{ opacity: 0.5, color: settings.translationcolor }}>
                {original}
              </div>
            )}
          </>
        )}
      </header>
    </div>
  );
}

interface Values {
  bgcolor: string;
  textcolor: string;
  voicecolor: string;
}

const Settings = (props: any) => {
  const history = useHistory();

  const [visible, set_visible] = useState(true);
  const [settings, set_settings] = React.useState<any>({
    bgcolor: props.settings.bgcolor,
    textcolor: props.settings.textcolor,
    voicecolor: props.settings.voicecolor,
    fontsize: props.settings.fontsize,
    translationcolor: props.settings.translationcolor,
    transcriptcolor: props.settings.transcriptcolor,
    inputlanguage: props.settings.inputlanguage,
    outputlanguage: props.settings.outputlanguage,
    enable_transcriptcopy: props.settings.enable_transcriptcopy,
    readback: props.settings.readback ?? false,
  });
  const showModal = () => {
    set_visible(true);
  };
  const handleCancel = () => {
    if (props.isRecording) {
      set_settings({
        bgcolor: props.settings.bgcolor,
        textcolor: props.settings.textcolor,
        voicecolor: props.settings.voicecolor,
        fontsize: props.settings.fontsize,
        translationcolor: props.settings.translationcolor,
        transcriptcolor: props.settings.transcriptcolor,
        inputlanguage: props.settings.inputlanguage,
        outputlanguage: props.settings.outputlanguage,
        enable_transcriptcopy: props.settings.enable_transcriptcopy,
      });
      set_visible(false);
    } else {
      history.push("/");
    }
  };

  const handleOk = async () => {
    await props.save(settings);
    set_visible(false);
  };

  const changeSettings = (setting: any, value: any) => {
    let copy = settings;
    copy[setting] = value;
    set_settings({ ...copy });
  };

  return (
    <div
      style={{
        textAlign: "left",
        position: "absolute",
        width: "calc(100% - 5px)",
        bottom: "5px",
        left: "5px",
      }}
    >
      <Button
        size={"large"}
        type="text"
        onClick={showModal}
        className="mainButton"
        style={{ fontSize: "20px" }}
      >
        {props.isRecording ? (
          <Badge dot color="red" style={{ position: "absolute" }}>
            <SettingOutlined />
          </Badge>
        ) : (
          <>
            <SettingOutlined />
          </>
        )}
      </Button>
      <Modal
        title="Subtle - Settings"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>
          <Button
            style={{ width: "100%" }}
            danger={props.isRecording}
            type={"primary"}
            onClick={() => props.toggleRecording()}
          >
            {props.isRecording ? (
              <>
                <PauseCircleFilled /> Pause session
              </>
            ) : (
              <>
                <AudioFilled /> Start session
              </>
            )}
          </Button>
        </p>
        <Divider />
        <p>
          <small>
            <strong style={{ opacity: 0.4, textTransform: "uppercase" }}>
              INPUT LANGUAGE
            </strong>
          </small>
          <br />
          <Select
            defaultValue={
              languages.filter(
                (language: any) => language.code === settings.inputlanguage
              )?.[0]?.name ?? ""
            }
            style={{ width: "100%" }}
            onChange={(value: any) => changeSettings("inputlanguage", value)}
          >
            {languages.map((language: any) => (
              <Option value={language.code}>{language.name}</Option>
            ))}
          </Select>
        </p>
        <p>
          <small>
            <strong style={{ opacity: 0.4, textTransform: "uppercase" }}>
              OUTPUT LANGUAGE
            </strong>
          </small>
          <br />
          <Select
            defaultValue={
              languages.filter(
                (language: any) => language.code === settings.outputlanguage
              )?.[0]?.name ?? ""
            }
            style={{ width: "100%" }}
            onChange={(value: any) => changeSettings("outputlanguage", value)}
          >
            {languages.map((language: any) => (
              <Option value={language.code}>{language.name}</Option>
            ))}
          </Select>
        </p>
        <Divider />
        <p>
          <small>
            <strong style={{ opacity: 0.4, textTransform: "uppercase" }}>
              BACKGROUND COLOR
            </strong>
          </small>
          <br />
          <input
            style={{ width: 150 }}
            name="color"
            type="color"
            value={settings.bgcolor}
            onChange={(e: any) => changeSettings("bgcolor", e.target.value)}
          />
        </p>
        <p>
          <small>
            <strong style={{ opacity: 0.4, textTransform: "uppercase" }}>
              DICTATION TEXT COLOR
            </strong>
          </small>
          <br />
          <input
            style={{ width: 150 }}
            name="color"
            type="color"
            value={settings.transcriptcolor}
            onChange={(e: any) =>
              changeSettings("transcriptcolor", e.target.value)
            }
          />
        </p>
        <p>
          <small>
            <strong style={{ opacity: 0.4, textTransform: "uppercase" }}>
              TRANSLATION TEXT COLOR
            </strong>
          </small>
          <br />
          <input
            style={{ width: 150, height: 30 }}
            name="color"
            type="color"
            value={settings.translationcolor}
            onChange={(e: any) =>
              changeSettings("translationcolor", e.target.value)
            }
          />
        </p>
        <Divider />
        <Checkbox
          checked={settings.enable_transcriptcopy}
          onChange={(e: any) =>
            changeSettings("enable_transcriptcopy", e.target.checked)
          }
        >
          Display original dictation after translation
        </Checkbox>
        <br />
        <Checkbox
          checked={settings.readback}
          onChange={(e: any) => changeSettings("readback", e.target.checked)}
        >
          Enable translation readback
        </Checkbox>
      </Modal>
    </div>
  );
};

export default Translator;
