import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.less";
import Translator from "./Translator";
import Website from "./Website";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/app">
          <Translator />
        </Route>
        <Route path="/">
          <Website />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
